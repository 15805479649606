import { SignUpEnum } from 'services/types/generic/enums';
import { Button, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box, Card } from '@mui/material';
import { useCustomAuth } from 'hooks';

export const SignupCard = ({ icon, title, subtitle, buttonTitle, type }) => {
  const { loginWithRedirect } = useCustomAuth();
  const handelClick = () => {
    if (type === SignUpEnum.Buyer) {
      loginWithRedirect({ authOptions: { authorizationParams: { screen_hint: 'signup' } } });
    } else {
      window.location.href = `https://${process.env.REACT_APP_BACKSTORE_BASE_URL}/redirecting-signup`;
    }
  };
  return (
    <Card className={styles.sign__card}>
      <Box className="flex flex-col h-full">
        {icon}
        <Typography variant="h5" component="h5" className="text-darkGray text-start py-5 max-md:py-3">
          {title}
        </Typography>

        <Typography variant="body1" className="text-midDarkGray mb-5">
          {subtitle}
        </Typography>

        <Button
          size="medium"
          onClick={() => {
            handelClick();
          }}
          className="mt-auto self-start"
        >
          {buttonTitle}
        </Button>
      </Box>
    </Card>
  );
};
